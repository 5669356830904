import React, { useState, useEffect } from 'react'
import { App, getDwvVersion } from 'dwv'
import styled from 'styled-components'
import { CircularProgress, Notistack } from 'vlab-frontend-components'
import { getFromCache, saveToCache } from '../../../../utils/indexedDB'

const StyledDicomViewer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height + 'px' || '600px'};
  width: ${(props) => props.width + 'px' || '700px'};

  overflow: hidden;
`

function DicomViewer({ dicomUrl, height, width }) {
  const { enqueueSnackbar } = Notistack.useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({
    versions: {
      dwv: getDwvVersion(),
      react: React.version,
    },
    tools: {
      Scroll: { options: [] },
      ZoomAndPan: { options: [] },
      WindowLevel: { options: [] },
      Draw: { options: ['Ruler'] },
    },
    selectedTool: 'Scroll',
    dwvApp: null,
    metaData: {},
    dataLoaded: false,
  })

  useEffect(() => {
    const dwvApp = new App()
    setState((prev) => ({ ...prev, dwvApp }))

    return () => {
      dwvApp?.reset()
    }
  }, [])

  useEffect(() => {
    if (dicomUrl && state.dwvApp) {
      loadDicom(dicomUrl)
    }
  }, [dicomUrl, state.dwvApp])

  const loadDicom = async (url) => {
    try {
      setIsLoading(true)
      let file
      let cachedFile
        
      try {
        cachedFile = await getFromCache(url);
      } catch (error) {
        cachedFile = null;
        console.error("Erro ao buscar imagem no cache", error);
      }

      if (cachedFile) {
        file = cachedFile;
      } else {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Falha ao buscar o arquivo DICOM.");
        const blob = await response.blob();
        file = new File([blob], "tmp.dcm");
        
        try {
          await saveToCache(url, blob);
        } catch (error) {
          console.error("Erro ao salvar imagem no cache", error);
        }
      }

      state.dwvApp.init({
        dataViewConfigs: {
          '*': [{ divId: 'layerGroup0', orientation: 'default' }],
        },
        tools: state.tools,
      })

      state.dwvApp.addEventListener('load', () => {
        setState((prev) => ({ ...prev, dataLoaded: true }))
      })

      state.dwvApp.addEventListener('loaderror', (error) => {
        enqueueSnackbar({
          message: 'Tivemos um erro ao tentar abrir o arquivo DICOM para visualização.',
          variant: 'error',
        })
      })

      window.addEventListener('resize', state.dwvApp.onResize)
      state.dwvApp.loadFiles([file])
    } catch (error) {
      enqueueSnackbar({
        message: 'Tivemos um erro ao carregar o arquivo DICOM',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container className="dicom-viewer" height={height} width={width}>
      {isLoading ? (
        <CircularProgress style={{ width: '50px', height: '50px' }} />
      ) : (
        <StyledDicomViewer id="layerGroup0" className="layerGroup"></StyledDicomViewer>
      )}
    </Container>
  )
}

export default DicomViewer
