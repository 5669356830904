import {
  CircularProgress,
  Dialog,
  DownloadIcon,
  PDFViewer,
  Typography,
} from 'vlab-frontend-components'

import AccordionResult from '../AccordionResult'
import { ResultFiles, Results } from '../../../../types/Results'
import { useEffect, useState } from 'react'
import {
  AccordionHeader,
  AccordionWrapper,
  EmptyContainer,
  ImageWrapper,
  ResultContainer,
  Wrapper,
} from './styles'
import VideoRender from '../VideoRender'
import FlexContainer from 'vlab-frontend-components/dist/cjs/components/FlexContainer/FlexContainer'
import { useApi } from '../../../../hooks/useAPi'
import { StatusDownloadDialog } from '../../../../components/StatusDownloadDialog'
import { MedicalReports } from '../../../../types/medicalReports'
import { format } from 'date-fns'
import JSZip from 'jszip'
import { LoadingContainer } from '../../../../components/LoadingContainer'
import { se } from 'date-fns/locale'
import DicomViewer from '../DicomViewer'
import { cacheImage } from '../../../../utils/indexedDB'
import dicomParser from 'dicom-parser'
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import cornerstone from 'cornerstone-core';

interface ModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  results: Results[]
  selectedReport: MedicalReports | null
}

interface ImageViewerProps {
  src: string
  alt?: string
  width?: string
  height?: string
}

export interface AllItems {
  url: string
  filename: string
  file_type: number
}

const ImageViewer = ({ src, alt = '', width = '100%', height = 'auto' }: ImageViewerProps) => {
  const [cachedSrc, setCachedSrc] = useState<string | null>(null)

  useEffect(() => {
    const fetchAndCacheImage = async () => {
      try {
        const cachedUrl = await cacheImage(src)
        setCachedSrc(cachedUrl)
      } catch (error) {
        console.error('Erro ao carregar a imagem:', error)
        setCachedSrc(src)
      }
    }

    fetchAndCacheImage()
  }, [src])

  if (!cachedSrc) return <p>Carregando imagem...</p>

  return (
    <ImageWrapper height={height} width={width}>
      <img src={cachedSrc} alt={alt} />
    </ImageWrapper>
  )
}

export default function Modal({ open, setOpen, results, selectedReport }: ModalProps) {
  const [selectedFile, setSelectedFile] = useState<Partial<ResultFiles> | null>(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedsResults, setSelectedsResults] = useState<any[]>([])
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const [downloadStatus, setDownloadStatus] = useState('')
  const [percentageVideoDownload, setPercentageVideoDownload] = useState(0)
  const [downloadError, setDownloadError] = useState(false)
  const [allItems, setAllItems] = useState<AllItems[]>([])
  const { getDownloadExam } = useApi()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const downloadFilesHelper = async (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function zipNameByReport(): string {
    if (!selectedReport) return ''
    if (selectedReport.iam_user_name) {
      return `${selectedReport.iam_user_name}_${format(new Date(selectedReport.created_at), 'dd_MM_yyyy')}.zip`
    } else {
      return `${selectedReport.identifier}_${format(new Date(selectedReport.created_at), 'dd_MM_yyyy')}.zip`
    }
  }

  const downloadFiles = async () => {
    setIsLoading(true)
    try {
      if (selectedsResults.length === 1) {
        const singleFile = selectedsResults[0]
        const response = await fetch(singleFile.url)

        if (!response.ok) {
          throw new Error('Erro ao baixar o arquivo')
        }

        let blob = await response.blob()
        if(singleFile.file_type === 4) {
          const imageBlob = await convertDICOMToPNG(blob)
          if (imageBlob) {
            blob = imageBlob
          }
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = singleFile.file_type === 4 ? `${singleFile.filename}.png` : singleFile.filename
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      } else if (selectedsResults.length > 1) {
        const zip = new JSZip()

        const downloadPromises = selectedsResults.map(async (file) => {
          const response = await fetch(file.url)

          if (!response.ok) {
            throw new Error('Erro ao baixar o arquivo')
          }

          const blob = await response.blob()

          if(file.file_type === 4) {
            const jpgBlob = await convertDICOMToPNG(blob)
            if (jpgBlob) {
              zip.file(file.filename + '.png', jpgBlob)
            }
          } else {
            zip.file(file.filename, blob)
          }
        })

        await Promise.all(downloadPromises)

        zip.generateAsync({ type: 'blob' }).then((content) => {
          const zipUrl = window.URL.createObjectURL(content)
          const zipLink = document.createElement('a')
          zipLink.href = zipUrl
          zipLink.download = zipNameByReport()
          document.body.appendChild(zipLink)
          zipLink.click()
          document.body.removeChild(zipLink)
          window.URL.revokeObjectURL(zipUrl)
        })
      }
    } catch (error) {
      
    } finally {
      setIsLoading(false)
    }
  }

  const convertDICOMToPNG = async (dicomData: Blob): Promise<Blob | null> => {
    try {
      cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
      cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  
      cornerstoneWADOImageLoader.configure({
        useWebWorkers: true,
        usePDFJS: false,
      });
  
      const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicomData);
  
      return new Promise((resolve, reject) => {
        cornerstone.loadImage(imageId).then((image) => {
          // Criar um div para renderizar a imagem do DICOM
          const div = document.createElement('div');
          document.body.appendChild(div);
          div.style.width = `${image.width}px`;
          div.style.height = `${image.height}px`;
  
          cornerstone.enable(div);
          cornerstone.displayImage(div, image);
  
          // Criar um canvas para copiar a imagem
          setTimeout(() => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if (!context) {
              document.body.removeChild(div);
              return reject(new Error("Erro ao criar o contexto do canvas"));
            }
            canvas.width = image.width;
            canvas.height = image.height;
  
            const cornerstoneCanvas = div.querySelector('canvas') as HTMLCanvasElement;
            if (cornerstoneCanvas) {
              context.drawImage(cornerstoneCanvas, 0, 0, canvas.width, canvas.height);
  
              canvas.toBlob((blob) => {
                document.body.removeChild(div);
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error("Erro ao converter para Blob"));
                }
              }, 'image/png');
            } else {
              document.body.removeChild(div);
              reject(new Error("Canvas do Cornerstone não encontrado"));
            }
          }, 500);
        }).catch(error => reject(error));
      });
    } catch (error) {
      console.error("Erro ao processar o DICOM:", error);
      return null;
    }
  };
  

  return (
    <Dialog
      isOpen={open}
      onClose={() => setOpen(false)}
      title="Resultados"
      contentStyle={{
        width: '100%',
        height: '100%',
        justifyContent: 'flex-start',
        maxWidth: '90vw',
        maxHeight: '90vh',
        position: 'relative',
      }}
    >
      {isLoading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '0',
            left: '0',
            background: 'white',
            opacity: '0.8',
          }}
        >
          <CircularProgress style={{ width: '50px', height: '50px' }} />
        </div>
      )}
      <StatusDownloadDialog
        open={openDownloadDialog}
        setOpen={(value) => {
          setOpenDownloadDialog(value)
        }}
        percentage={percentageVideoDownload}
        status={downloadStatus}
        error={downloadError}
      />
      <Wrapper>
        <ResultContainer>
          <AccordionWrapper>
            <AccordionHeader>
              <FlexContainer alignItems="center" gap="8px">
                <input
                  type="checkbox"
                  checked={selectedsResults.length > 0}
                  onClick={() => {
                    if (selectedsResults.length > 0) {
                      setSelectedsResults([])
                    } else {
                      setSelectedsResults(allItems)
                    }
                  }}
                />
                {selectedsResults.length > 0 && (
                  <Typography color="black" type="poppinsBodyS" style={{ fontWeight: 300 }}>
                    {selectedsResults.length} selecionados
                  </Typography>
                )}
              </FlexContainer>

              <div
                style={{ cursor: selectedsResults.length > 0 ? 'pointer' : 'not-allowed' }}
                onClick={() => {
                  if (selectedsResults.length > 0) {
                    downloadFiles()
                  }
                }}
              >
                <DownloadIcon />
              </div>
            </AccordionHeader>
            {results.map((result) => (
              <>
                <AccordionResult
                  result={result}
                  key={result.id}
                  setSelectedFile={setSelectedFile}
                  setSelectedsResults={setSelectedsResults}
                  selectedsResults={selectedsResults}
                  setAllItems={setAllItems}
                />
              </>
            ))}
          </AccordionWrapper>
          <div style={{ width: !selectedFile ? '60%' : 'fit-content' }}>
            {!selectedFile && (
              <EmptyContainer>
                <Typography color="black" type="poppinsBodyL" style={{ fontWeight: 300 }}>
                  Selecione um arquivo para visualizar
                </Typography>
              </EmptyContainer>
            )}

            {selectedFile && selectedFile.file_type === 1 && (
              <PDFViewer
                file={selectedFile.url || ''}
                height={'600'}
                width={windowSize <= 870 ? '300' : '700'}
              />
            )}
            {selectedFile && selectedFile.file_type === 2 && (
              <ImageViewer src={selectedFile.url || ''} height="100%" width="100%" />
            )}
            {selectedFile && selectedFile.file_type === 3 && (
              <div
                style={{
                  width: windowSize <= 870 ? 300 : 600,
                  height: '100%',
                }}
              >
                <VideoRender
                  openReport={true}
                  selectedReport={{
                    exam: selectedFile.url || '',
                  }}
                />
              </div>
            )}
            {selectedFile && selectedFile.file_type === 4 && (
              <DicomViewer
                dicomUrl={selectedFile.url || ''}
                height={'500'}
                width={windowSize <= 870 ? '300' : '600'}
              />
            )}
          </div>
        </ResultContainer>
      </Wrapper>
    </Dialog>
  )
}
